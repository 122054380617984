<template>
    <div class="application-page">
        <base-nav />

        <base-inner-banner img-type="application"  />

        <h1 class="text-center page-h1 mb-0 pb-0">{{ $t('Nav_application')}} </h1>

        <applicaiton-detail />

        <base-footer /> 
    </div>
</template>

<script>
import ApplicaitonDetail from '@/components/application/ApplicationDetail.vue';

export default {
    name: 'application',

    metaInfo() {
        return {
            title: this.$t('Nav_application'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('Head_description')
                } ,{
                    name: 'keywords',
                    content: this.$t('Head_keywords')
                }, {
                    name: 'author',
                    content: this.$t('Head_author')
                }, {
                    name: 'copyright',
                    content: this.$t('Head_copyright')
                }
            ]
        }
    },

    components: {
        ApplicaitonDetail,
    },


    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.application-page{
    background: $light-gray-color;
}
</style>
